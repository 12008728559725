var exports = {};

/**
 * This module serves as an Async wrapper for LocalStorage
 */
exports.setItem = function (key, value) {
  return new Promise(function (resolve) {
    localStorage.setItem(key, value);
    resolve();
  });
};

exports.getItem = function (key) {
  return Promise.resolve(localStorage.getItem(key));
};

exports.removeItem = function (key) {
  return new Promise(function (resolve) {
    localStorage.removeItem(key);
    resolve();
  });
};

export default exports;